/* eslint no-console: 0 */
import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue'
require('../src/utils/localization');

import I18n from '../src/utils/localization';
Vue.prototype.I18n = I18n;
window.I18n = I18n;

import AuthApp from '../authentication_shell.vue'
import axios from "axios";
require('./layout-dialogs-toasts');
require('./networking');

document.addEventListener('DOMContentLoaded', () => {
  const el = document.body.appendChild(document.createElement('auth_app'));
  const clientDebugx = (msg) => {
    if (navigator.sendBeacon) {
      let data = new FormData();
      data.append('report', JSON.stringify(msg));
      data.append('user','unknown')
      navigator.sendBeacon('/__rdb', data);
    } else {
      axios.post('/__rdb',{report: msg,user:'unknown'}).then(success=>{console.log(success)},error=>{console.log(error)}).catch(error=>{console.log(error)});
    }
  };
  if(console.log.name == 'nrWrapper'){
    if(console.context) {
      console.log = console.context().log
      console.warn = console.context().warn
      console.debug = console.context().debug
    }
  }

  //Vue.mixin({ beforeCreate: function(){
  //    this.$remoteLog = function(msg) {
  //      clientDebugx(msg)
  //    }
  //}})
  const rl = {
    methods: {
      remoteLog: function (msg) {
        clientDebugx(msg);
      },
    }
  }
  const app = new Vue({
    el,
    render: h => h(AuthApp),
    mixins: [rl],
  });
  app.remoteLog = clientDebugx;
  window.AztecApplication = app;
  app.$mobile_ux = window.matchMedia("(max-width: 1000px)").matches
  app.$mobile_ulx = window.matchMedia("(orientation: landscape) and (max-width: 1000px)").matches
  const iPad = !!(navigator.userAgent.match(/(iPad)/) || (navigator.platform === "MacIntel" && typeof navigator.standalone !== "undefined"))
  if(iPad)
    app.$mobile_ux = false
  window.onresize = function(){
    if(iPad){
      app.$mobile_ux = false
      app.$mobile_ulx = window.matchMedia("(orientation: landscape) and (max-width: 1000px)").matches
    }else {
      app.$mobile_ux = window.matchMedia("(max-width: 1000px)").matches
      app.$mobile_ulx = window.matchMedia("(orientation: landscape) and (max-width: 1000px)").matches
    }
  }
  window.addEventListener('orientationchange',function(){
    if(iPad){
      app.$mobile_ux = false
      app.$mobile_ulx = window.matchMedia("(orientation: landscape) and (max-width: 1000px)").matches
    }else {
      app.$mobile_ux = window.matchMedia("(max-width: 1000px)").matches
      app.$mobile_ulx = window.matchMedia("(orientation: landscape) and (max-width: 1000px)").matches
    }
  });
  console.log("dom ready");
});
