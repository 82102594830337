<template lang="pug">
    div.container-fluid
        div.row
            div.col-12.justify-content-left
                h1.mt-2 {{I18n.t('auth.labels.session_welcome')}}
            div.col-12.justify-content-left
                p.h5.mt-1 {{I18n.t('auth.labels.session_login')}}
        div.mt-1.d-flex.justify-content-left.mb-2
            form.form-inline.mb-0(novalidate="novalidate" action="/users/sign_in.json" method="post" accept-charset="UTF-8" @submit.prevent="loginUser()")
                input(name="utf8" type="hidden" value="✓")
                div.w-100
                    span.field
                        b-form-input(required :state="login.length > 1" aria-label="name" v-b-tooltip="{trigger: $mobile_ux ? 'focus':'hover'}"  name="login" v-model="login" :title="I18n.t('auth.tooltips.login_tooltip')" type="text" :autofocus="true" autocomplete="off" autocapitalize="none" :placeholder="I18n.t('auth.placeholder.login')")
                    span.field.ml-1
                        b-form-input(aria-label="password" v-b-tooltip="{trigger: $mobile_ux ? 'focus':'hover'}" name="pass" v-model="pass" :title="I18n.t('auth.tooltips.enter_current_password')" type="password" autocomplete="off" :placeholder="I18n.t('auth.placeholder.password')")
                    div.w-100.text-left.mt-2(v-if="$mobile_ux && !$mobile_ulx")
                      button.btn-primary.btn(:disabled="login.length == 0") {{I18n.t('auth.labels.login')}}
                    span.field.ml-2(v-if="!$mobile_ux || ($mobile_ux && $mobile_ulx)")
                      button.btn-primary.btn(:disabled="login.length == 0") {{I18n.t('auth.labels.login')}}
                div.mt-1(v-if="isFedramp || show_otp")
                  span.field
                    b-form-checkbox(v-model="show_otp" :checked="true" :unchecked-value="false")
                      | {{I18n.t('auth.labels.use_2fa')}}
                  span.field.ml-1(v-if="show_otp")
                    b-form-input(aria-label="otp" name="otp" v-model="otp" type="password" autocomplete="off" :placeholder="I18n.t('auth.placeholder.otp')")
        div.d-flex.justify-content-left.mt-1.pl-0.clearfix.mb-1(v-if="$correctional == false")
            div(:data-intro="I18n.t('auth.intros.forgot_password')")
                b-link.btn.btn-link.pl-0(href="#" @click="forgotPassword()" role="button") {{I18n.t('auth.labels.forgot_password')}}
            div(:data-intro="I18n.t('auth.intros.need_confirm')")
                b-link.btn.btn-link.pl-0(href="#" @click="reconfirmAccount()" role="button") {{I18n.t('auth.labels.need_confirmation')}}
        div.d-flex.justify-content-left.mt-1.pl-0.clearfix.mb-1.flex-wrap
            div(:data-intro="I18n.t('auth.intros.pivcac')" v-if="usePivcac")
                b-link.btn.btn-link.pl-0(href="/users/auth/saml"  v-b-tooltip="{trigger: $mobile_ux ? 'manual':'hover'}" :title="I18n.t('auth.intros.pivcac')")
                    img(src="/images/login_with_pivcac.png" width="156" height="32" :alt="I18n.t('auth.intros.pivcac')")
            div(:data-intro="I18n.t('auth.intros.okta')" v-if="useOkta && ($correctional == false || usePivcac)")
                b-link.btn.btn-link.pl-0(href="/users/auth/okta"  v-b-tooltip="{trigger: $mobile_ux ? 'manual':'hover'}" :title="I18n.t('auth.intros.okta')")
                    img(src="/images/login_with_okta.png" width="156" height="32" :alt="I18n.t('auth.intros.okta')")
            div(:data-intro="I18n.t('auth.intros.google')" v-if="$correctional == false")
                b-link.btn.btn-link.pl-0(href='/users/auth/google_oauth2' v-b-tooltip="{trigger: $mobile_ux ? 'manual':'hover'}" :title="I18n.t('auth.intros.google')")
                    img(src="/images/login_with_google.png" width="133" height="32" :alt="I18n.t('auth.intros.google')")
            div(:data-intro="I18n.t('auth.intros.amazon')" v-if="$correctional == false")
                b-link.btn.btn-link.pl-0(href="/users/auth/amazon"  v-b-tooltip="{trigger: $mobile_ux ? 'manual':'hover'}" :title="I18n.t('auth.intros.amazon')")
                    img(src="/images/login_with_amazon.png" width="156" height="32" :alt="I18n.t('auth.intros.amazon')")
            div(:data-intro="I18n.t('auth.intros.microsoft')" v-if="useOffice365")
                b-link.btn.btn-link.pl-0(href="/users/auth/azure_oauth2_v2"  v-b-tooltip="{trigger: $mobile_ux ? 'manual':'hover'}" :title="I18n.t('auth.intros.microsoft')")
                    img(src="/images/login_with_microsoft.png" width="156" height="32" :alt="I18n.t('auth.intros.microsoft')")

</template>

<script>
    import axios from 'axios';
    //import remoteLogger from '../../internal_libs/remote_log_mixin';
    const getQueryStringParams = query => {
        return query
            ? (/^[?#]/.test(query) ? query.slice(1) : query)
                .split('&')
                .reduce((params, param) => {
                        let [key, value] = param.split('=');
                        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                        return params;
                    }, {}
                )
            : {}
    };
    export default {
        name: "LoginControl",
        data: function(){
            return {
                login: '',
                pass: '',
                loginTry: 0,
                otp:'',
                show_otp: false
            }
        },
        computed:{
          isFedramp(){
            return (window._app_env == 'fedramp' || window._app_env == 'stateramp');
          },
          useOkta(){
            return window._okta == true;
          },
          usePivcac(){
            return window._picvac == true;
          },
          useOffice365(){
            return window._azure == true;
          }
        },
        //mixins:[remoteLogger],
        mounted: function(){
            let p = getQueryStringParams(window.location.search);
            if(p["rl"]) {
                let rs = JSON.parse(Base64.decode(p["rl"]));
                this.login = rs["u"];
                this.pass = rs["p"];
                this.$nextTick( ()=>{
                    this.$flash.info(this.I18n.t(rs["m"]));
                    setTimeout( ()=>{
                        this.loginUser();
                    },3000)
                });
            }
        },
        methods: {
            forgotPassword: function(){
                let me = this;
                this.$swal.fire({
                    title: me.I18n.t('auth.labels.forgot_password_prompt'),
                    input: 'text',
                    inputLabel: 'Email',
                    inputPlaceholder: 'example@example.com',
                    inputAttributes: {'aria-label': 'Email Address'},
                    cancelButtonClass: 'btn btn-outline-danger ml-1',
                    cancelButtonText: this.I18n.t('forms.cancel'),
                    showCancelButton: true,
                    confirmButtonClass: 'btn btn-primary ml-1',
                    buttonsStyling: false
                }).then((result) => {
                    if(result.value) {
                        let data = {user:{email: result.value}};
                        if (typeof newrelic == 'object') {
                          newrelic.setCustomAttribute('user_email', result.value);
                        }
                        this.$emit('load');
                        axios.post('/users/password.json',data).then(success => {
                            me.$emit('unload');
                            me.$flash.success(me.I18n.t('auth.labels.recovery_sent'));
                        },error => {
                            me.$emit('unload');
                            me.$flash.error(me.I18n.t('auth.errors.cant_reset'));
                            me.remoteLog('reset password error [authentication/session/56]'+error);
                        }).catch(error=>{
                            me.$emit('unload');
                            me.$flash.error(me.I18n.t('auth.errors.cant_send_email'));
                            me.remoteLog('reset password [authentication/session/60]'+error.message);
                        });
                    }
                });
            },
            reconfirmAccount: function(){
                let me = this;
                this.$swal.fire({
                    title: me.I18n.t('auth.labels.reconfirm_account_label'),
                    input: 'text',
                    inputLabel: 'Email',
                    inputPlaceholder: 'example@example.com',
                    inputAttributes: {'aria-label': 'Email Address'},
                    cancelButtonClass: 'btn btn-outline-danger ml-1',
                    cancelButtonText: this.I18n.t('forms.cancel'),
                    showCancelButton: true,
                    confirmButtonClass: 'btn btn-primary ml-1',
                    buttonsStyling: false
                }).then((result) => {
                    if(result.value) {
                        let data = {user:{email: result.value}};
                        if (typeof newrelic == 'object') {
                          newrelic.setCustomAttribute('user_email', result.value);
                        }
                        this.$emit('load');
                        axios.post('/users/confirmation.json',data).then(success => {
                            me.$emit('unload');
                            me.$flash.success(me.I18n.t('auth.labels.confirmation_sent'));
                        },error => {
                            if(error.response)
                              error = error.response;
                            me.$emit('unload');
                            me.$flash.error(me.I18n.t('auth.errors.cant_confirm'));
                            me.remoteLog('confirm password [authentication/session/82]'+error);
                        }).catch(error=>{
                            me.$emit('unload');
                            me.$flash.error(me.I18n.t('auth.errors.cant_confirm_email'));
                            me.remoteLog('confirm password [authentication/session/86]'+error);
                        });
                    }
                });
            },
            loginUser: function(){
                // login function!
                if(this.login.length == 0)
                    return;
                //this.$emit('load');
                this.$flash.loading(this.I18n.t('flash.authenticating'));
                let data = {user:{
                        login: this.login,
                        password: this.pass
                    }};
                if(this.show_otp && this.otp.length > 1)
                  data.user.otp_attempt = this.otp;
                let me = this;
                let cookies = document.cookie.split(";");
                const deleteCookie = function(cookiename)
                {
                    let d = new Date();
                    d.setDate(d.getDate() - 1);
                    let expires = ";expires="+d;
                    let name=cookiename;
                    let value="";
                    document.cookie = name + "=" + value + expires + "; path=/";
                }
                for (let i = 0; i < cookies.length; i++)
                {
                    let spcook =  cookies[i].split("=");
                    if(spcook[0] != 'swap_flag')
                        deleteCookie(spcook[0]);
                }
                if (typeof newrelic == 'object') {
                  newrelic.setCustomAttribute('user_name', this.login);
                }
                axios.post('/users/sign_in.json',data,{maxRedirects: 0,handlerEnabled:false}).then(
                    success=>{
                        /**
                         * IE Support for location values
                         */
                        let cp = Object.assign({},success.headers);
                        let orurl = success.request.getResponseHeader('X-Redirect');
                        let rurl = orurl || cp.redirect || '';
                        let url_redirect = {pathname: rurl || ''};
                        //console.log("Got url ",url_redirect,rurl,orurl,success,success.request,success.data);
                        if(url_redirect.pathname.indexOf('nextgen') > 0){
                            me.$flash.loading(me.I18n.t('devise.failure.incompat'));
                            me.$flash.dataBusy();
                            window.location = url_redirect.pathname;
                            return;
                        }
                        if(url_redirect.pathname == '/') {
                            // redirect to main app
                            console.log("Login response",success.data);
                            if(typeof success.data == 'object') {
                              let user_obj = success.data;
                              console.log("EULA check ",user_obj.read_eula," ",user_obj);
                              if(user_obj.read_eula != true) {
                                me.$flash.close();
                                // force popup the eula, and send an update to the server
                                me.$emit('terms');
                                return;
                              }
                            }
                            me.$flash.loading(me.I18n.t('flash.app_shell_load',{name: success.data.customer,display: success.data.display_name}));
                            me.$flash.dataBusy();
                            window.location = '/';
                            return;
                        }
                        if(url_redirect.pathname.includes('/users/cancel')) {
                            // no oauth link
                            me.$flash.loading(me.I18n.t('flash.redirecting'));
                            me.$flash.dataBusy();
                            window.location = rurl;
                            return;
                        }
                        if(url_redirect.pathname.includes('/users/sign_up')) {
                            // key redemption
                            me.$flash.loading(me.I18n.t('flash.redirecting'));
                            me.$flash.dataBusy();
                            window.location = rurl;
                            return;
                        }
                        if(url_redirect.pathname.includes('/users/confirmation')) {
                            // email confirmation link
                            me.$flash.loading(me.I18n.t('flash.redirecting'));
                            me.$flash.dataBusy();
                            window.location = rurl;
                            return;
                        }
                        if(url_redirect.pathname.includes('/users/password')) {
                          // unlock link
                          me.$flash.loading(me.I18n.t('flash.redirecting'));
                          me.$flash.dataBusy();
                          window.location = rurl;
                          return;
                        }
                        if(url_redirect.pathname.includes('/users/unlock')) {
                            // unlock link
                            me.$flash.loading(me.I18n.t('flash.redirecting'));
                            me.$flash.dataBusy();
                            window.location = rurl;
                            return;
                        }
                        if(typeof(success.data) == 'string'){
                            //console.log("Got Success Call",url_redirect,"*","",success.request.status)
                            if(success.request.status != 302){
                                if(success.request.getResponseHeader('RESET-SESSION')) {
                                    if(me.loginTry > 2) {
                                        me.loginTry = 0;
                                        me.$flash.warn(me.I18n.t('auth.error.cookies'));
                                    } else {
                                        me.$nextTick(()=>{
                                            me.loginTry += 1;
                                            console.log("Potential bad cookies found, wiping sessiond data and re-submitting.")
                                            axios.get('/users/sign_out',{}).then( ()=>{
                                                console.log("Session was reset trying again");
                                                me.loginUser();
                                            }).catch((x)=>{
                                                console.log(x);
                                                me.loginUser();
                                            });
                                        })
                                    }
                                } else {
                                    if(success.request.status == 200 && url_redirect.pathname == ""){
                                      window.location.reload(true)
                                      return;
                                    }
                                    console.log(success);
                                    me.$flash.error(success.data);
                                }
                            }
                            return;
                        }
                        if(typeof(success.data) == 'object') {
                            // we got a user object!
                            let user_obj = success.data;
                            if(user_obj.read_eula != true) {
                                me.$flash.close();
                                // force popup the eula, and send an update to the server
                                me.$emit('terms');
                                return;
                            } else {
                                window.location = '/';
                                return;
                            }
                        }
                    },
                    error=>{
                        //me.$emit('unload');
                        console.log("Got login error ",error);
                        me.$flash.close();
                        if (error.status == 500) {
                            me.$toastr.error(me.I18n.t('flash.system_error'))
                        } else {
                            if (error.status != 302) {
                                if(error.status == 424) {
                                    me.show_otp = true;
                                    me.$toastr.info(me.I18n.t('boot.need_otp'))
                                    return;
                                }
                                if(error.status == 429) {
                                    me.$toastr.info("Login system is busy, try again in a minute")
                                }
                                if(error.status == 428) {
                                  let systems = [];
                                  if(!this.isFedramp) {
                                    systems.push('Google')
                                    systems.push('Amazon')
                                  }
                                  if(this.useOkta) {
                                    systems.push('Okta')
                                  }
                                  if(this.usePivcac) {
                                    systems.push('PivCAC')
                                  }
                                  if(this.useOffice365) {
                                    systems.push('Office365')
                                  }
                                  me.$toastr.info(me.I18n.t('boot.need_omniauth',{p: systems.join(', ')}))
                                  return;
                                }
                                if(error.status == 401) {
                                    me.$flash.error(error.data);
                                    axios.post('/__rdb',{report: error.data,user: me.login}).then(success=>{},error=>{console.log(error)}).catch(error=>{console.log(error)});
                                    return;
                                }
                                if(error.status != 500) {
                                    me.$flash.error(me.I18n.t('boot.cache_error'));
                                    window.location.reload(true);
                                }
                            } else {
                                let cp = Object.assign({},success.headers);
                                let rurl = success.request.getResponseHeader('X-Redirect');
                                rurl = rurl || cp.redirect || '/';
                                window.location = rurl;
                            }
                        }
                    }
                ).catch(
                    error=>{
                        console.log("Catch hit ",error);
                        me.$flash.error(me.I18n.t('flash.system_error'));
                        console.log(error);
                    }
                )
            },
        }
    }
</script>

<style scoped>

</style>
