<template lang="pug">
  div.container-fluid.authentication_application(:locale="$active_locale" :aria-hidden="[false]")
    nav#auth_nav_header.navbar.navbar-expand-lg.navbar-light.bg-light.border-bottom
      div.navbar-brand#auth_logo(style="cursor:pointers;" role="img" aria-label="Aztec Logo, with right facing striped arrow.")
      div.navbar-text.mr-auto.d-sm-none.d-md-flex
    div.container-fluid.justify-content-left(ref='loading_container' role="main")
      // login sesison component
      login-session(v-if="mode == 'login'" @terms="readTerms" @load="showLoader" @unload="hideLoader")
      // password component
      password-control(v-if="mode == 'password'" @load="showLoader" @unload="hideLoader" @login="loginMode")
      // unlock component
      registration-control(v-if="mode == 'registration'" @load="showLoader" @unload="hideLoader" @login="loginMode")
      // registration component
    div.col-12.position-bottom.mt-4.justify-content-left.mb-4.px-0
      div.d-flex.justify-content-left(v-if="!$mobile_ux" role="list")
        // nornal
        span(role="listitem")
          button.btn.btn-link(href="#" :data-intro="I18n.t('auth.intros.test')" @click="$refs.machineTester.testComputer()" tabindex="0" role="button") {{I18n.t('auth.labels.test_computer')}}
        span(v-if="$correctional === false" role="listitem")
          a.btn.btn-link(href="https://www.aztecsoftware.com/shop/" target="_blank" :data-intro="I18n.t('auth.intros.account')"  :aria-label="I18n.t('auth.labels.open_account') + '(Opens in a new window)'" rel="noreferrer" tabindex="0" role="button")
            | {{I18n.t('auth.labels.open_account')}}
            i.fa.fa-external-link.ml-1
        span.d-block(role="listitem")
          button.btn.btn-link(href="#" @click="$showHelp($el)" tabindex="0" role="button") {{I18n.t('auth.labels.need_help')}}
      div.w-100.bg-primary.py-1#color_lookup
      div.d-flex.justify-content-left(v-if="!$mobile_ux" role="list")
        span(role="listitem")
            button.btn.text-blue(@click="swapLang('en')" v-if="$active_locale === 'es'" v-b-tooltip.hover="" title="Cambiar a inglés" aria-label="Cambiar a inglés" role="button")
                i.fas.fa-language.fa-fw
                span.ml-2 EN
            button.btn.text-blue(@click="swapLang('es')" v-else v-b-tooltip.hover="" title="Switch to spanish" aria-label="Switch to spanish" role="button")
                i.fas.fa-language.fa-fw
                span.ml-2 ES
            span.btn.text-muted {{ui_version}}
        span(role="listitem")
          button.btn.btn-link.text-muted.cursor(@click="$refs.supportModal.showTerms()" :data-intro="I18n.t('auth.intros.toc')" tabindex="0" role="button") {{I18n.t('auth.labels.toc')}}
        span(role="listitem")
          button.btn.btn-link.text-muted.cursor(@click="$refs.supportModal.showFerpa()" :data-intro="I18n.t('auth.intros.ferpa')" tabindex="0" role="button") {{I18n.t('auth.labels.ferpa')}}
        span(role="listitem")
          button.btn.btn-link.text-muted.cursor(@click="$refs.supportModal.showPrivacy()" :data-intro="I18n.t('auth.intros.policy')" tabindex="0" role="button") {{I18n.t('auth.labels.privacy')}}
        span(v-if="isFedramp" role="listitem")
          button.btn.btn-link.text-muted.cursor(@click="$refs.supportModal.showDoc('/images/fedramp_warning.txt','Computer Usage Policy','NA')"  tabindex="0" role="button") {{I18n.t('a11y.computer_use_policy')}}
        span.btn.text-muted.float-right(role="presentation")
          i.fa.fa-copyright
          | Aztec Software, LLC 2015 - {{curr_year}}
      div.d-flex.justify-content-left(v-if="$mobile_ux" role="list")
        // xs only
        span(role="listitem")
          button.btn.text-blue(@click="swapLang('en')" v-if="$active_locale === 'es'" v-b-tooltip.hover="" title="Cambiar a inglés" tabindex="0" role="button" aria-label="Cambiar a inglés")
            i.fas.fa-language.fa-fw
            span.ml-2 EN
          button.btn.text-blue(@click="swapLang('es')" v-else v-b-tooltip.hover="" title="Switch to spanish" aria-label="Switch to spanish" role="button")
            i.fas.fa-language.fa-fw
            span.ml-2 ES
          span.btn.text-muted {{ui_version}}
      div.d-flex.justify-content-left(v-if="$mobile_ux" role="list")
        span(role="listitem")
          button.btn.btn-link.text-blue.px-1(@click="$refs.supportModal.showTerms()" :data-intro="I18n.t('auth.intros.toc')" tabindex="0" role="button") {{I18n.t('auth.labels.toc')}}
        span(role="listitem")
          button.btn.btn-link.text-blue.px-1(@click="$refs.supportModal.showFerpa()" :data-intro="I18n.t('auth.intros.ferpa')" tabindex="0" role="button") {{I18n.t('auth.labels.ferpa')}}
        span(role="listitem")
          button.btn.btn-link.text-blue.px-1(@click="$refs.supportModal.showPrivacy()" :data-intro="I18n.t('auth.intros.policy')" tabindex="0" role="button") {{I18n.t('auth.labels.privacy')}}
      div.d-flex.justify-content-left(v-if="$mobile_ux" role="presentation")
        span(v-if="isFedramp" role="listitem")
          button.btn.btn-link.text-blue.px-1(@click="$refs.supportModal.showDoc('/images/fedramp_warning.txt',I18n.t('ribbon_bar.labels.banner'),'NA')" tabindex="0" role="button") {{I18n.t('ribbon_bar.labels.banner')}}
        span.btn.text-muted(role="presentation")
          i.fa.fa-copyright
          | Aztec Software, LLC 2015 - {{curr_year}}
      div.d-flex.justify-content-left(role="presentation")
        p.text-muted.ml-3 {{I18n.t('a11y.authorized_only')}}
    support-modal(ref='supportModal' @document_read="docRead")
    machine-test(ref='machineTester')
    v-announcer
</template>

<script>
    import axios from 'axios';
    import LoginSession from "./src/views/authentication/session";

    function getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        let results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };
    const jwplayerkey = 'bgYbjykJeXdwn4T0BI8KcdL7guSJBdoVNtLvKUtFnrb6hZz9'; //'+Q4/t9OpQ/lcQkyPCLJzpQlFgsPlK0ZeZ/q4x7bng1rfGjBg4b8zcX8enkE=';
    export default {
        name: "AuthorizationShell",
        components: {
           'login-session': LoginSession,
           'password-control': ()=> ({component: import("./src/views/authentication/password.vue")}), //PasswordView,
           'registration-control': ()=> ({component: import("./src/views/authentication/registration.vue")}),//RedemptionControl,
           'machine-test': ()=> ({component: import("./src/views/shared/test_machine")}),//machineTester,
           'support-modal': ()=> ({component: import("./src/views/shared/system_document")})//supportDocs
        },
        data: function(){
            return {
                loader: null,
                help_doc: '',
                help_title: '',
                modal_size: 'fs',
                mode: 'login',
                user_id: '',
                ui_version: 'NextGen V9',
                curr_year: new Date().getFullYear()
            }
        },
        computed: {
            title(){
                this.help_title;
            },
            isFedramp(){
              return window._app_env == 'fedramp' || window._app_env == 'stateramp';
            }
        },
        methods: {
            swapLang: function(lc){
                if((window.location.protocol == 'https:'))
                  this.$cookie.set("swap_flag",lc,{secure:true,expires: '1D',samesite:'none'});
                else
                  this.$cookie.set("swap_flag",lc,{expires: '1D'});
                this.I18n.locale = lc;
                this.$flash.loading(this.I18n.t('profile.prefs.reloading_lang'));
                axios.post('/__rdb',{report: "swapping locales",'user':'unknown'}).then(success=>{window.location.reload();},error=>{window.location.reload();}).catch(error=>{window.location.reload();});
            },
            home: function(){
                window.location = '/';
            },
            showLoader: function(){
                this.$flash.loading(this.I18n.t('flash.please_wait'));
            },
            hideLoader: function(){
                this.$flash.close();
            },
            readTerms: function(){
                if (typeof newrelic == 'object') {
                    newrelic.addPageAction('Read Terms');
                }
                // force popup the eula, and send an update to the server
                this.$once('document_read',function(){
                    axios.put('/accept/2').then(
                        success=>{},
                        error=>{}
                    ).catch(error=>{
                    });
                    window.location = '/';
                });
                this.$refs.supportModal.showTerms();
            },
            loginMode: function(){
                this.mode = 'login';
                console.log("login mode");
                window.history.replaceState( {} , 'login', '/users/sign_in' );
            },
            passwordMode: function(){
                this.mode = 'password';
                if (typeof newrelic == 'object') {
                    newrelic.addPageAction('Change Password');
                }
            },
            docRead: function(){
                this.$emit('document_read');
            },
        },
        created: function(){
            if(window._app_env != 'nuc') {
              require('jwplayer-8.5.3/jwplayer');
              window.jwplayer.key = jwplayerkey;
            } else {
              window.jwplayer = require('jwplayer-7.8.0/jwplayer');
            }
            this.scry();
        },
        mounted: function(){
            this.$correctional = window.correctional;
            console.log("Auth shell mounted",this.$correctional);
            this.ui_version = window.ui_version || 'V9';
            window.jwplayer.key = jwplayerkey;
            if (typeof newrelic == 'object') {
                newrelic.setCustomAttribute('ui_version', window.ui_version || 'V9');
            }
            if(getUrlParameter('ems')) {
              this.$flash.error(this.I18n.t(getUrlParameter('ems')))
              window.history.replaceState( {} , 'login', '/' );
            }
            // change password email link
            if(window.location.pathname.includes('password')) {
                if (typeof newrelic == 'object') {
                    let interaction = newrelic.interaction();
                    newrelic.setCurrentRouteName('password')
                    interaction.setName('User Change Password');
                    interaction.actionText('User Change Password');
                    this.$nextTick( function(){
                        interaction.end();
                    })
                }
                this.passwordMode();
            }
            if(window.location.pathname == '/') {
                if (typeof newrelic == 'object') {
                    let interaction = newrelic.interaction();
                    newrelic.setCurrentRouteName('sign_in')
                    interaction.setName('User Login');
                    interaction.actionText('User Login');
                    this.$nextTick( function(){
                        interaction.end();
                    })
                } else {
                    console.log("newrelic not available")
                }
            }
            // login request
            if(window.location.pathname.includes('sign_in')) {
                if (typeof newrelic == 'object') {
                    let interaction = newrelic.interaction();
                    newrelic.setCurrentRouteName('sign_in')
                    interaction.setName('User Login');
                    interaction.actionText('User Login');
                    this.$nextTick( function(){
                            interaction.end();
                    })
                }
                this.loginMode();
            }
            // key redeption
            if(window.location.pathname.includes('sign_up')) {
                this.mode = 'registration';
                this.registration_id = getUrlParameter('key');
                this.registration_type = getUrlParameter('type');
                if (typeof newrelic == 'object') {
                    let interaction = newrelic.interaction();
                    newrelic.setCurrentRouteName('sign_up')
                    interaction.setName('Registration');
                    interaction.actionText('Registration');
                    interaction.setAttribute('Registration',{key:this.registration_id,type: this.registration_type});
                    this.$nextTick( function(){
                        interaction.end();
                    })
                }
                if(this.registration_id == '' || this.registration_id == undefined) {
                    this.mode = 'login';
                    window.history.replaceState( {} , 'login', '/users/sign_in' );
                }
            }
            // oauth canceled
            if(window.location.pathname.includes('cancel')) {
                // capture error
                let emsg = 'auth.unlinked_account';
                if(window.location.search.slice(1).length > 0) {
                    emsg = window.location.search.slice(1);
                }
                this.loginMode();
                if (typeof newrelic == 'object') {
                    let interaction = newrelic.interaction();
                    newrelic.setCurrentRouteName('oauth_cancel')
                    interaction.setName('Oauth Cancel');
                    interaction.actionText('OAUTH Cancel');
                    this.$nextTick( function(){
                        interaction.end();
                    })
                }
                this.$flash.warn(this.I18n.t(emsg,{time:'10'}))
            }
        }
    }
</script>

<style lang="scss">
  .help_icon {
    cursor: pointer;
  }
  // step 1
  li:not(.active) div#step-BasicInformation0.checked {
      background-color: green;
      color: white;
      border-color: green !important;
  }
  li:not(.active) div#step-BasicInformation0.checked + .stepTitle {
      background-color:inherit !important;
      border-color: inherit !important;
      color: green !important;
  }
  // step 2
  li:not(.active) div#step-AddressInformation1.checked {
      background-color: green;
      color: white;
      border-color: green !important;
  }
  li:not(.active) div#step-AddressInformation1.checked + .stepTitle {
      background-color:inherit !important;
      border-color: inherit !important;
      color: green !important;
  }
  // step 3
  li:not(.active) div#step-AccountInformation2.checked {
      background-color: green;
      color: white;
      border-color: green !important;
  }
  li:not(.active) div#step-AccountInformation2.checked + .stepTitle {
      background-color:inherit !important;
      border-color: inherit !important;
      color: green !important;
  }
  // step 4
  li:not(.active) div#step-OrganizationInformation3.checked {
      background-color: green;
      color: white;
      border-color: green !important;
  }
  li:not(.active) div#step-OrganizationInformation3.checked + .stepTitle {
      background-color:inherit !important;
      border-color: inherit !important;
      color: green !important;
  }
  // step 5
  li:not(.active) div#step-OrganizationAddress4.checked {
      background-color: green;
      color: white;
      border-color: green !important;
  }
  li:not(.active) div#step-OrganizationAddress4.checked + .stepTitle {
      background-color:inherit !important;
      border-color: inherit !important;
      color: green !important;
  }
  #color_lookup {
      //margin-left:-15px;
      //margin-right:-15px;
 }
  a.btn-social {
      height: 48px;
      width: 48px;
  }
  .cursor {
    cursor: pointer;
  }




</style>
